var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("vue-headful", {
              attrs: {
                title: _vm.appName + " - Refresh Service: " + _vm.service.name
              }
            }),
            _c(
              "gov-back-link",
              {
                attrs: {
                  to: {
                    name: "services-show",
                    params: { service: _vm.service.id }
                  }
                }
              },
              [_vm._v("\n      Back to " + _vm._s(_vm.service.type) + "\n    ")]
            ),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "two-thirds" } },
                      [
                        _c(
                          "gov-heading",
                          { attrs: { size: "m" } },
                          [
                            _c("gov-caption", { attrs: { size: "m" } }, [
                              _vm._v(_vm._s(_vm.service.name))
                            ]),
                            _vm._v(
                              "\n            Refresh " +
                                _vm._s(_vm.service.type) +
                                "\n          "
                            )
                          ],
                          1
                        ),
                        !_vm.refreshed
                          ? [
                              _vm.form.$errors.any()
                                ? _c(
                                    "gov-error-summary",
                                    {
                                      attrs: {
                                        title:
                                          "There was a problem when confirming"
                                      }
                                    },
                                    [
                                      _c(
                                        "gov-list",
                                        _vm._l(_vm.form.$errors.all(), function(
                                          error,
                                          field
                                        ) {
                                          return _c("li", {
                                            key: field,
                                            domProps: {
                                              textContent: _vm._s(error[0])
                                            }
                                          })
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("gov-body", [
                                _vm._v(
                                  "\n              Please confirm that this " +
                                    _vm._s(_vm.service.type) +
                                    " is still up to date?\n            "
                                )
                              ]),
                              _vm.form.$submitting
                                ? _c(
                                    "gov-button",
                                    { attrs: { disabled: "" } },
                                    [
                                      _vm._v(
                                        "\n              Confirming...\n            "
                                      )
                                    ]
                                  )
                                : _c(
                                    "gov-button",
                                    { on: { click: _vm.onRefresh } },
                                    [_vm._v(" Confirm ")]
                                  )
                            ]
                          : [
                              _c("gov-body", [
                                _vm._v(
                                  "The " +
                                    _vm._s(_vm.service.type) +
                                    " has been freshed!"
                                )
                              ]),
                              _c(
                                "gov-button",
                                {
                                  attrs: {
                                    to: {
                                      name: "services-show",
                                      params: { service: _vm.service.id }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Back to " +
                                      _vm._s(_vm.service.type) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }